<template>
  <div class="faq-details py-8">
    <FaqAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="faq"
        name="FAQ"
        redirect="/hub/faqs"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="faq-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="faq-details__divider"></v-divider>

            <FaqForm :faq="faq" :access-building="accessBuilding" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Hub FAQ
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import FaqForm from '@/views/Home/Hub/Faqs/components/FaqForm'
import FaqAppbar from '@/views/Home/Hub/Faqs/components/FaqAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'FaqDetails',

  components: {
    ViewingTitle,
    FaqAppbar,
    FaqForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      faq: (state) => state.faq.faqDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    displayName() {
      return this.faq ? this.faq.header : null
    },

    hasDeletePermission() {
      return (
        validatePermissions([PERMISSION.FAQS_DELETE], this.permissions) &&
        this.accessBuilding
      )
    },

    accessBuilding() {
      return access(this.faq, this.buildingList)
    },
  },

  methods: {
    ...mapActions({
      getFaqDetails: 'faq/getFaqDetails',
    }),

    ...mapMutations({
      clearFaqDetails: 'faq/clearFaqDetails',
    }),

    async getFaq() {
      this.loading = true
      await this.getFaqDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getFaq()
  },

  destroyed() {
    this.clearFaqDetails()
  },

  watch: {
    $route() {
      this.getFaq()
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
